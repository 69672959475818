import * as Yup from 'yup'
import moment from 'moment'
import { VerifyPhone } from '../../../redux/api/location'
import { VerifyCPF } from '../../../utils/utils'
import { envs } from '../../../redux/api/windowServerData'

const message = 'REQUIRED_FIELD'

const regexEmail = (email) => {
    const re =
        /^(([^*<>()[\]\\.,;:\s@"]+(\.[^*<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export const VerifyPhoneNumber = async (n) => {
    try {
        const res = await VerifyPhone(n)
        return res.success
    } catch (error) {
        console.log(error)
    }
}

export const ValidateEmail = (email) =>
    regexEmail(email)
        ? fetch(
              //   `${envs.REACT_APP_GERMINI_API_URL}/Consumer/ValidateConsumerEmailDuplicity?email=${email}`,
              `${envs.REACT_APP_GERMINI_API_URL}/Consumer/ValidateConsumerEmailDuplicity?email=${email}`,
              {
                  headers: {
                      'Content-Type': 'application/json-patch+json',
                      appId: 'CSM1803',
                      //   companyInternalName: 'germini',
                  },
                  Accept: 'application/json',
              },
          ).then((response) => {
              if (response.status === 200) {
                  return response.text().then((text) => {
                      const data = text && JSON.parse(text)
                      return data.success
                  })
              } else {
                  return false
              }
          })
        : true

const isValidDate = (dateString) => {
    // Parse the date parts to integers
    const parts = dateString.split('/')
    const day = parseInt(parts[0], 10)
    const month = parseInt(parts[1], 10)
    const year = parseInt(parts[2], 10)

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) return false

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1]
}

const prevStateDate = (date) => {
    const regex = /([*])([*])([/])[\d][\d]([/])([*])([*])([*])([*])/
    const found = date.match(regex)
    return found ? true : false
}

export const registerFormValidation = Yup.object({
    associatedData: Yup.boolean().nullable(),
    name: Yup.string()
        .nullable()
        .required(message)
        .test('name', 'Enter your full name', function (value) {
            if (value) {
                return value.trim().split(' ').length >= 2
            }
            return true
        })
        .typeError(message),
    disabledDateOfBirth: Yup.boolean().nullable(),
    isMEI: Yup.boolean().nullable(),
    dateOfBirth: Yup.string()
        .nullable()
        .required(message)
        .when('cpf', (cpf, dateOfBirth) =>
            dateOfBirth
                .test(
                    'date compare',
                    'DATE_INVALID',
                    function (dateOfBirth = '') {
                        if (dateOfBirth.includes('*')) {
                            return prevStateDate(dateOfBirth)
                        } else {
                            const date = dateOfBirth.replace(/\s/g, '')
                            if (date) {
                                if (isValidDate(date)) {
                                    return true
                                } else {
                                    return false
                                }
                            }
                            return false
                        }
                    },
                )
                .test(
                    'date compare',
                    'MINIMUM_AGE',
                    function (dateOfBirth = '') {
                        if (cpf && cpf.replace(/[^\d]+/g, '').length > 11) {
                            return true
                        }
                        if (dateOfBirth.includes('*')) {
                            return prevStateDate(dateOfBirth)
                        } else {
                            const date = dateOfBirth
                                .replaceAll(/\s/g, '')
                                .replaceAll('*', '')
                            if (date.length === 10) {
                                if (isValidDate(date)) {
                                    const minDate = moment().subtract(
                                        18,
                                        'years',
                                    )
                                    if (
                                        moment(date, 'DD/MM/YYYY').isAfter(
                                            minDate,
                                        )
                                    ) {
                                        return false
                                    }
                                    return true
                                } else {
                                    return false
                                }
                            }
                            return true
                        }
                    },
                ),
        ),
    stateRegistration: Yup.string()
        .nullable()
        .when(['cpf', 'stateRegistrationWaiver'], {
            is: (cpf, stateRegistrationWaiver) => {
                return (
                    cpf &&
                    cpf.replace(/[^\d]+/g, '').length > 11 &&
                    stateRegistrationWaiver === false
                )
            },
            then: Yup.string().required(message).typeError(message),
            otherwise: Yup.string().nullable(),
        }),

    listState: Yup.string()
        .nullable()
        .when(['cpf', 'stateRegistrationWaiver'], {
            is: (cpf, stateRegistrationWaiver) => {
                return (
                    cpf &&
                    cpf.replace(/[^\d]+/g, '').length > 11 &&
                    stateRegistrationWaiver === false
                )
            },
            then: Yup.string().required(message).typeError(message),
            otherwise: Yup.string().nullable(),
        }),
    gender: Yup.string()
        .nullable()
        .when('cpf', (cpf, gender) =>
            cpf && cpf.replace(/[^\d]+/g, '').length > 11
                ? gender.nullable()
                : gender.required(message).typeError(message),
        ),
    email: Yup.string()
        .nullable()
        .test('email', 'Email_INVALID_VALUE', function (email) {
            const { emailPre, InvalidEmail } = this.parent

            if (
                email &&
                email.includes('***@') &&
                email === emailPre &&
                !InvalidEmail
            ) {
                return true
            }
            if (email) {
                return regexEmail(email)
            }
            return true
        })
        .required(message)
        .typeError(message),

    phoneNumber2: Yup.string()
        .nullable()
        .required(message)
        .typeError(message)
        .test('valid', 'INVALID_MOBILE_NUMBER', function (x) {
            const { IsError, phoneNumber2Pre } = this.parent

            if (x && x.includes('*') && x === phoneNumber2Pre && !IsError) {
                return true
            }
            return x && x.replace(/[^\d]+/g, '').length > 10
        })
        .test('validar-celular', 'INVALID_MOBILE_NUMBER', function (x) {
            const { IsError, phoneNumber2Pre } = this.parent

            if (x && x.includes('*') && x === phoneNumber2Pre && !IsError) {
                return true
            }
            if (x) {
                let rawValue = x.replace(/[^\d]+/g, '')

                const regex = /^[0-9]{2}9[0-9]{8}$/
                return regex.test(rawValue)
            }
        }),
    address: Yup.object({
        zipCode: Yup.string().nullable().required(message),
        location: Yup.string().when('associatedData', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().required(message).typeError(message),
        }),
        number: Yup.string().when('associatedData', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().required(message).typeError(message),
        }),
        stateId: Yup.string().required(message).typeError(message),
        district: Yup.string().when('associatedData', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().required(message).typeError(message),
        }),
        cityId: Yup.string().when('associatedData', {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string().required(message).typeError(message),
        }),
        aditionalInfo: Yup.string()
            .notRequired()
            .max(10, 'MAXIMUM_10_CHARACTERS'),
        complement: Yup.string().notRequired().max(10, 'MAXIMUM_10_CHARACTERS'),
    }),
    nameRP: Yup.string().when('cpf', {
        is: (cpf) => cpf && cpf.replace(/[^\d]+/g, '').length > 11,
        then: Yup.string().required(message).typeError(message),
        otherwise: Yup.string().nullable(),
    }),
    cpfRP: Yup.string().when('cpf', {
        is: (cpf) => cpf && cpf.replace(/[^\d]+/g, '').length > 11,
        then: Yup.string()
            .required(message)
            .typeError(message)
            .test('cpf', 'cpf - INVALID_VALUE', function () {
                const { cpfRP } = this.parent
                if (cpfRP) {
                    let rawValue = cpfRP.replace(/[^\d]+/g, '')
                    if (rawValue?.length <= 11) {
                        return VerifyCPF(cpfRP)
                    }
                }
                return true
            }),
        otherwise: Yup.string().nullable(),
    }),
    emailRP: Yup.string().when('cpf', {
        is: (cpf) => cpf && cpf.replace(/[^\d]+/g, '').length > 11,
        then: Yup.string()
            .required(message)
            .test('email', 'THE_EMAIL_ADDRESS_IS_INVALID', function (email) {
                const { emailRpPre } = this.parent
                if (email && email.includes('***@') && email === emailRpPre) {
                    return true
                }
                if (email) {
                    return regexEmail(email)
                }
                return true
            })
            .max(255, 'THE_EMAIL_ADDRESS_MUST_HAVE_A_MAXIMUM_OF_255_CHARACTERS')
            .typeError(message),
    }),
    phoneNumberRP: Yup.string().when('cpf', {
        is: (cpf) => cpf && cpf.replace(/[^\d]+/g, '').length > 11,
        then: Yup.string()
            .required(message)
            .typeError(message)
            .test('valid', 'MOBILE_INVALID_VALUE', (x) => {
                if (x && x.includes('*') && x.length === 15) {
                    return true
                }
                return x && x.replace(/[^\d]+/g, '').length > 10
            })
            .test('validar-celular', 'INVALID_MOBILE_NUMBER', (x) => {
                if (x) {
                    if (x && x.includes('*') && x.length === 15) {
                        return true
                    }
                    let rawValue = x.replace(/[^\d]+/g, '')

                    const regex = /^[0-9]{2}9[0-9]{8}$/
                    return regex.test(rawValue)
                }
            })
            .test('valid', 'INVALID_MOBILE_NUMBER', (x) => {
                if (x) {
                    if (x && x.includes('*') && x.length === 15) {
                        return true
                    }
                    let rawValue = x.replace(/[^\d]+/g, '')
                    return (
                        rawValue &&
                        rawValue.substring(2, 3) >= 6 &&
                        rawValue.substring(2, 3) <= 9
                    )
                }
            }),
        otherwise: Yup.string().nullable(),
    }),
    password: Yup.string()
        .test(
            'string compare',
            'THE_PASSWORD_CANNOT_CONTAIN_CPF_CNPJ_DATE',
            function (password) {
                const { cpf, dateOfBirth, phoneNumber2, email } = this.parent
                if (password) {
                    if (cpf) {
                        if (password.includes(cpf)) {
                            return false
                        }
                    }
                    if (dateOfBirth) {
                        if (
                            password.includes(dateOfBirth.replaceAll('/', ''))
                        ) {
                            return false
                        }
                        if (password.includes(dateOfBirth)) {
                            return false
                        }
                        if (
                            password.includes(
                                moment(dateOfBirth).format('MM/DD/YY'),
                            )
                        ) {
                            return false
                        }
                    }
                    if (phoneNumber2) {
                        const phoneNumber = phoneNumber2?.replace(' ', '') || ''
                        const passWard = password.replace(' ', '') || ''

                        if (passWard.includes(phoneNumber)) {
                            return false
                        }
                    }
                    if (email) {
                        if (password.includes(email)) {
                            return false
                        }
                    }
                }

                return true
            },
        )
        .test(
            'string compare',
            'NO_MORE_THAN_3_REPEATED_NUMBERS_IN_ORDER',
            function (password = '') {
                for (let i = 0; i <= 9; i++) {
                    if (password.includes(`${i}${i}${i}`)) {
                        return false
                    }
                }
                return true
            },
        )
        .test(
            'string compare',
            'THE_PASSWORD_CANNOT_HAVE_MORE_THAN_3_SEQUENTIAL_NUMBERS',
            (password = '') => {
                let sequentialCount = 1
                for (let i = 0; i < password.length - 1; i++) {
                    const currentChar = password[i]
                    const nextChar = password[i + 1]
                    if (isNaN(currentChar) || isNaN(nextChar)) {
                        sequentialCount = 1
                    } else if (
                        parseInt(currentChar) + 1 ===
                        parseInt(nextChar)
                    ) {
                        sequentialCount++
                        if (sequentialCount > 3) {
                            return false
                        }
                    } else {
                        sequentialCount = 1
                    }
                }
                return true
            },
        )
        .test(
            'string compare',
            'THE_ENTERED_PASSWORD_DOES_NOT_COMPLY_WITH_THE_DEFINED_POLICIES',
            function (password = '') {
                if (password) {
                    const numberMatches = password.match(/\d+/g) //eslint-disable-line
                    const specialCharacters =
                        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/ //eslint-disable-line
                    const specialCharactersMatches =
                        specialCharacters.test(password) //eslint-disable-line

                    if (!numberMatches || !specialCharactersMatches)
                        return false
                }
                return true
            },
        )
        .test(
            'string compare',
            'THE_PASSWORD_MUST_CONTAIN_AT_LEAST_1_LETTER',
            function (password = '') {
                if (password) {
                    const letterMatches = password.match(/[a-zA-Z]+/g) //eslint-disable-line
                    if (!letterMatches) return false
                }
                return true
            },
        )

        .required(message)
        .min(8, 'MINIMUM_PASSWORD_LENGTH')
        .max(20, 'MAXIMUM_PASSWORD_LENGTH'),

    confirmPassword: Yup.string()
        .max(20, 'MAXIMUM_PASSWORD_LENGTH')
        .required(message)
        .typeError(message)
        .oneOf(
            [Yup.ref('password'), null],
            'CONFIRM_FIELD_OTHER_THAN_PASSWORD',
        ),
    useTerms: Yup.boolean().oneOf([true], message),
    useRegulation: Yup.boolean().oneOf([true], message),
    usePrivacyPolicy: Yup.boolean().oneOf([true], message),
    newsletter: Yup.boolean().notRequired(),
    isPreRegistration: Yup.boolean(),
    isStateDisabled: Yup.boolean(),
})
